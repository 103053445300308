import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { dealerTabsDuck } from '../../../ducks';
import { getSelectedTabFromState, resetTab } from '../../../utilities';
import useCreateDealerDetailsPageEvent from '../../../hooks/useCreateDealerDetailsPageEvent';

import styled from '@emotion/styled';
import { useDevice } from '@atc/react-device';
import { get as _get } from '../../../utilities/common';
import { useRouter } from 'next/router';
import { TAB_NAME } from '../../../constants';
import { colors, Section } from '@argo/principles';
import { Tab, Tabs, Rectangle } from '@argo/ui-elements';
import { AboutTabContainer, ReviewsTabContainer, ServicesTabContainer, SalesTabContainer } from '.';

const setPaddingLeft = (isXs) => (isXs && 'padding-left: 16px;');

const TabsContainer = styled.div`
    backgroundColor: 'transparent';
    .border {
        background-color: ${colors.blue.airForceBlue};
    }
    
    div[data-cmp="tabsContainer"] {
        ${({ isXs }) => setPaddingLeft(isXs)}
    }
`;

const RectangleContainer = styled(Section)`
    row-gap: 10px;
`;

const renderContent = (tabs) => tabs.map((tab, index) => {
    let TabContent;
    switch (tab.name) {
        case TAB_NAME.SALES:
            TabContent = SalesTabContainer;
            break;
        case TAB_NAME.ABOUT:
            TabContent = AboutTabContainer;
            break;
        case TAB_NAME.REVIEWS:
            TabContent = ReviewsTabContainer;
            break;
        case TAB_NAME.SERVICE:
            TabContent = ServicesTabContainer;
            break;
        default:
            break;
    }

    return (
        <Tab
            key={tab.heading}
            eventKey={index}
            label={tab.heading}
            headingType="h2"
            href={tab.href}
        >
            <TabContent />
        </Tab>
    );
});

const DealerDetailsTabContainer = ({
    actions,
    tabs,
    selectedTab,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const router = useRouter();
    const createDealerDetailsPageEvent = useCreateDealerDetailsPageEvent();
    const onTabSelected = (index) => {
        const tabName = tabs[index].name;
        actions.setSelectedTab(index, tabName);
        resetTab(router, tabName);
        if (index !== selectedTab.index) createDealerDetailsPageEvent();
    };

    useEffect(() => {
        setIsLoading(tabs.length <= 0);
    }, [tabs]);

    useEffect(() => {
        /* do nothing */
    }, [selectedTab]);

    if (isLoading) {
        return (
            <RectangleContainer>
                {Array(3).fill('').map((_, i) => (
                    <Rectangle
                        key={i}
                        inverse
                        padding="xs"
                    />
                ))}
            </RectangleContainer>
        );
    }
    return (
        <TabsContainer
            isServiceTab={selectedTab?.name === TAB_NAME.SERVICE}
            isXs={isXs}
        >
            <Tabs
                data-cmp="tabsContainer"
                alignment="left"
                isNarrow={isXs}
                onTabSelected={onTabSelected}
                activeTabIndex={selectedTab?.index || 0}
                dynamicTabIndex={selectedTab?.index || 0}
                isSeoFriendly
            >
                {renderContent(tabs)}
            </Tabs>
        </TabsContainer>

    );
};

function mapStateToProps(state) {
    const tabData = dealerTabsDuck.selectors.getDuckState(state);
    const tabs = _get(tabData, 'tabs', []);
    const selectedTab = getSelectedTabFromState(state);
    return {
        tabs,
        selectedTab,
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    setSelectedTab: dealerTabsDuck.creators.setSelectedTab,
    setTabs: dealerTabsDuck.creators.setTabs,
});

export default connect(mapStateToProps, mapDispatchToProps)(DealerDetailsTabContainer);
