import { dealerSetMakeDuck } from '../ducks';
import { allMakeValue } from '../constants';
import allMakeListFallBack from '../services/service-reference/allMakeListFallBack.json';
import { fetchMakesFromSyndication } from '../fetchers';
import { fetchWithCache } from '../utilities';

const getMakeByMakeName = async (makeName = '') => {
    const syndicationMakes = await fetchWithCache(
        fetchMakesFromSyndication,
        { limit: 250 },
        'all-syndication-makes',
        3600000 //1 hour
    );
    const makeList = syndicationMakes.length > 0 ? syndicationMakes : allMakeListFallBack.items;
    const foundItem = makeList.find((make) => make.makeName?.toLowerCase() === makeName);
    return foundItem || null;
};

export default function setKbbMakeFromQuery() {
    return async (ctx) => {
        if (ctx.query.slug?.length > 1) {
            const makeSegment = ctx.query.slug[1];
            let mapedMake = ctx.data.makeList
                .find((make) => makeSegment === make.makeName.toLowerCase().replace(' ', '-'));

            if (!mapedMake) {
                const makeItem = await getMakeByMakeName(makeSegment);
                if (makeItem) {
                    ctx.data.makeList.push(makeItem);
                    mapedMake = makeItem;
                }
            }

            if (mapedMake) {
                ctx.query.make = mapedMake.makeName;
                ctx.query.makeCode = mapedMake.atcMake.code;
                ctx.query.seoMakeCode = ctx.query.make.replace(' ', '-').toLowerCase();
            }
        }

        ctx.store.dispatch(
            dealerSetMakeDuck.creators.setMake(ctx.query.make ? ctx.query.make : allMakeValue)
        );
    };
}
