import { useSelector } from 'react-redux';
import { useBrand } from 'reaxl-brand';
import { useCookies } from 'react-cookie';
import { userDuck, inventoryDuck } from '../ducks';
import { useDevice } from '@bonnet/next/device';
import { getActiveExperiments } from 'reaxl-optimizely';
import PersonalizationEngineModule from '../modules/PersonalizationEngineModule';
import getConfig from 'next/config';
import { get as _get, isEqual as _isEqual } from '../utilities/common';
import { pageInstanceId } from 'atc-js';
import { getSignedInCookie } from '@consumer/auth-cognito';
import { getAndSaveConsumerMetadata } from 'reaxl-analytics';

function formatData(data) {
    const dataFormated = JSON.stringify(data, (key, value) => {
        if (typeof value === 'number') {
            return String(value);
        }
        return (value || undefined);
    });
    return JSON.parse(dataFormated);
}

const analyticsPageName = (pageName) => ({
    fyd_ddp: 'ddp',
    dealerdetails: 'ddp',
    fyd_srl: 'srl',
    dealersearch: 'srp_dealer',
}[pageName]);

export default function useDataLayer(pageName) {
    const [cookies = {}] = useCookies(['MYAUTOTRADER', 'pxa_id', 'pixall_abc', 'pxa_realid']);
    const query = useSelector((state) => state.query ?? {});
    const user = useSelector(userDuck.selectors.getDuckState);
    const { brand } = useBrand();
    const device = useDevice();
    const { publicRuntimeConfig } = getConfig();
    const dataIsland = useSelector((state) => state.birf?.pageData ?? {});
    const personalization = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerInsights, _isEqual);
    const preferences = useSelector(PersonalizationEngineModule.duck.selectors.selectPreferences, _isEqual);
    const consumerAdTargets = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerAdTargets, _isEqual);
    const consumerAdTargetsWithoutCAMPBias = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerAdTargetsWithoutCAMPBias, _isEqual);
    const consumerMarketingSegments = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerMarketingSegments, _isEqual);
    const inventory = useSelector(inventoryDuck.selectors.getDuckState);
    const currentPageName = useSelector((state) => state.currentPageName);
    const currentAnalyticsPageName = pageName || currentPageName;
    const pageInst = useSelector((state) => _get(state, 'birf.pageData.page.BIRF.pg_inst'));

    const consumerMetadata = async () => {
        const isUserLoggedIn = isLoggedInRef.current;
        const hasAuthData = !_isEmpty(authConsumerMetadataRef.current);

        // If user is logged in, get from authentication
        // Otherwise get them from api
        const consumerMetadataResponse = isUserLoggedIn && hasAuthData ? { ...authConsumerMetadataRef.current } : await getAndSaveConsumerMetadata({
            isLoggedIn: isUserLoggedIn,
        });

        // Reset authConsumerMetadata ref
        // For edge case when user login, log out and then relogin with another account
        authConsumerMetadataRef.current = isUserLoggedIn ? { ...authConsumerMetadataRef.current } : {};

        return { ...consumerMetadataResponse };
    };

    const dataLayer = {
        catalog: brand,
        consumerInsights: {
            ...personalization,
            consumerCampaignPreferencesAddedByRule: preferences?.addedByRule,
        },
        consumerAdTargets,
        consumerAdTargetsWithoutCAMPBias,
        consumerMarketingSegments,
        consumerMetadata,
        application: {
            name: 'web',
            environment: publicRuntimeConfig.environment === 'prod' ? 'production' : 'qa',
            version: '1.0.0',
        },

        get user() {
            return {
                adBlockerState: 'off',
                get isLoggedIn() {
                    return typeof window !== 'undefined' ? getSignedInCookie() : '0';
                },
                zipCode: user.location?.zip,
                ivtMoatFlag: '0',
                dma: dataIsland.page?.location?.dma,
            };
        },
        page: {
            pageName: analyticsPageName(dataIsland?.page?.BIRF?.pg) ?? dataIsland?.page?.BIRF?.pg,
            // TODO: DEV we need to get access to this on load
            siteSection: dataIsland.page?.site?.section,
            // TODO: DEV we need to get access to this on load
            detailPageName: analyticsPageName(currentAnalyticsPageName),
            pixallId: cookies.pxa_id,
            experimentId: getActiveExperiments(),
            pixallABC: cookies.abc || cookies.pxa_realid,
            pixallPageInstanceId: pageInst ?? pageInstanceId.getRandomId(),
            pageType: analyticsPageName(currentAnalyticsPageName),
            pg: dataIsland?.page?.BIRF?.pg,
        },
        device,
        query,
        inventory,
    };

    return formatData(dataLayer);
}
