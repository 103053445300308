/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { get as _get } from '../../utilities/common';
import styled from '@emotion/styled';
import { Section, colors } from '@argo/principles';
import getConfig from 'next/config';
import { useDevice } from '@atc/react-device';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { ContactDealerModal } from '../../containers/dealerdetails/contactdealer';
import { showContactDealerDuck, inventoryActiveOwnerDuck } from '../../ducks';
import { sendClick } from 'reaxl-analytics';
import { injectScript } from 'atc-js';
import ServiceSpinner from './ServiceSpinner';
import { getDevicePadding } from '../../utilities/helpers';

const ServiceCTAsContainer = styled(Section)`
    display: flex;
    position: relative;
    min-height: 1200px;
    &::after {
        content: '';
        width: 100vw ;
        position: absolute;
        height: 100%;
        background: inherit;
        top: 0;
        transform: translate(-50%, 0);
        z-index: 1;
    }
    >*{
        position: relative;
        z-index: 2;
    }
    .promo-section-child button {
        border-style: none;
    }
`;

const ServiceTab = ({ svocid, source, apikey }) => {
    const [width, setWidth] = useState(0);
    const resizeCallback = useCallback(() => {
        const dealerDetailsNewSection = document.getElementById('dealerDetailsNewSection');
        if (dealerDetailsNewSection) {
            const containerWidth = Math.min(1280, dealerDetailsNewSection.clientWidth);
            setWidth((containerWidth - 40) + 'px');
        }
    }, []);

    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
        resizeCallback();
        window.addEventListener('resize', resizeCallback);
        return () => window.removeEventListener('resize', resizeCallback);
    }, []);

    return (
        isClient && (
            <service-tab-container
                svocid={svocid}
                source={source}
                apikey={apikey}
                style={{ width }}
            />
        )
    );
};

const ServiceCTAs = ({
    actions,
    owner,
    isKbb,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const { publicRuntimeConfig } = getConfig();
    const { hydraAtcKey, hydraKbbKey, hydraWebCompUrl } = publicRuntimeConfig;
    const source = isKbb ? 'KBB' : 'ATC';
    const hydraKey = isKbb ? hydraKbbKey : hydraAtcKey;
    const containerPadding = getDevicePadding(isXs);

    if (typeof window !== 'undefined' && window.CAIEventBus !== undefined) {
        window.CAIEventBus.on('contactServiceEvent', (data) => {
            const element = document.getElementById('contactDealerForm');

            if (device.type === 'desktop') {
                element.scrollIntoView({ block: 'center', behavior: 'smooth' });
            } else {
                //  render contact modal for mobile devices
                actions.setShowingContactDealer({ isSsuccess: false, mesage: '', showing: true });
            }
        });

    }

    useEffect(() => {
        injectScript(hydraWebCompUrl).then(() => setIsLoading(true));
        if (typeof window !== 'undefined' && window.CAIEventBus !== undefined) {
            window.CAIEventBus.on('serviceTab:adobeevent', (data) => {
                const customCmp = data.eventData?.cmp ? { ownerId: owner?.id || data.eventData?.dealerId, cmp: data.eventData?.cmp } : { ownerId: owner?.id || data.eventData?.dealerId };
                sendClick(data.eventData?.reaxlName, data.eventData?.event, customCmp);
            });
        }
        return () => setIsLoading(false);
    }, []);

    return (
        <>
            <ServiceCTAsContainer
                id="serviceTabContainer"
                fullWidth
                bgColor={colors.neutral.offWhite}
                verticalSpacingOverrides={{ top: '16px', bottom: '40px' }}
                horizontalSpacingOverrides={{ left: containerPadding, right: containerPadding }}
            >
                {!isLoading && <ServiceSpinner isKbb={isKbb} />}
                {isLoading && <ServiceTab
                    svocid={owner.id}
                    source={source}
                    apikey={hydraKey}
                />}
            </ServiceCTAsContainer>
            <div>
                <ContactDealerModal />
            </div>
        </>
    );
};

function mapStateToProps(state) {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state.owners, activeOwner, {});

    return {
        owner,
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    setShowingContactDealer: showContactDealerDuck.creators.setShowingContactDealer,
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCTAs);
