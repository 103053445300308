import getS3Data from '@kbbsdk/s3-data-service';
import { fetchWithCache } from '../utilities';

export default function withKbbNavFromS3(dataBucketName, navDataBucketKey) {
    return async (ctx) => {
        try {
            if (!ctx.data.navS3Data && typeof window === 'undefined') {
                const params = {
                    Bucket: dataBucketName,
                    Key: navDataBucketKey,
                };
                const { s3Data } = await fetchWithCache(
                    getS3Data,
                    params,
                    'nav-s3-data',
                    3600000 //1 hour
                );
                ctx.data.navS3Data = s3Data;
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('withKbbNavFromS3 - ', error);
        }
    };
}
