import { get as _get } from './common';
import { fetchMakesFromSyndication } from '../fetchers';
import { dealerCrawlPathDuck } from '../ducks';
import makeListFallBack2025 from '../services/service-reference/makeListFallBack2025.json';
import { fetchWithCache } from '.';

const getSyndicatedMakesFromState = async (state) => {
    const crawlPathData = dealerCrawlPathDuck.selectors.getDuckState(state);
    let currentMakeList = _get(crawlPathData, 'makeList', []);
    if (currentMakeList.length === 0) {
        try {
            currentMakeList = await fetchWithCache(
                fetchMakesFromSyndication,
                {
                    limit: 250,
                    yearId: new Date().getFullYear(),
                },
                'current-year-syndication-makes',
                3600000 //1 hour
            );
        } catch (error) {
            console.log(error);
        } finally {
            if (currentMakeList.length === 0) {
                currentMakeList = makeListFallBack2025.items;
            }
        }
    }

    return currentMakeList;
};

export default getSyndicatedMakesFromState;
